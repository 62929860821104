@import '../../../styles/variables';

// .system-configuration-file-tree {
//   div {
//     // The npm package sets this directly by element style,
//     // so unfortunately we need important here
//     overflow: visible !important;
//   }
// }

.file-tree-warning-row {
  background-color: $yellow100;
  position: static;
  &:before {
    border-bottom: solid 1px $gray-border;
    content: '';
    background-color: $yellow100;
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
  }
}

.file-tree-checked-row {
  background-color: $blue50;
  position: static;
  &::before {
    content: '';
    box-shadow: inset 3px 0px 0px $blue-border;
    color: $gray600;
    background-color: $blue50;
    height: 100%;
    width: 100%;
    margin-left: -4px;
    z-index: -1;
    position: absolute;
    left: 0px;
  }
}
