$gray-light: #fafafa;
$gray-medium: #f4f4f5;
$gray-border: #d4d4d8;
$gray600: #52525b;
$gray700: #2d3748;
$black-transparent: #00000020;
$red: #b91c1c;
$red2: #dc2626;
$blue-border: #0055c9;
$gray400: #a1a1aa;
$gray500: #71717a;
$blue50: #eff6ff;
$yellow100: #fef9c3;
$yellow200: #fef08a;

// viduals colours
$blue-select: #d9fbff;
$blue-original: #00e5ff;
$blue-darker: #05b7de;
$green-original: #00d700;
$green-darker: #419941;

//fonts
$default-font: Helvetica Neue;
$default-size: 16px;
$small-font: 12px;
$default-bold: 500;
$heavy-bold: 700;
