@import '../../../styles/variables';

.template_settings_45Degree_label {
  white-space: nowrap;
  transform: rotate(-45deg);
  /* Legacy vendor prefixes that you probably don't need... */
  /* Safari */
  -webkit-transform: rotate(-45deg);
  /* Firefox */
  -moz-transform: rotate(-45deg);
  /* IE */
  -ms-transform: rotate(-45deg);
  /* Opera */
  -o-transform: rotate(-45deg);

  transform-origin: left top;
}
