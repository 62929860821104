@import '../../styles/variables';

.modal-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  background-color: $black-transparent;
}

.modal {
  width: 360; //how to enforce height for delete modal
  height: 280;
  padding: 48px;
  border-radius: 8px;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}

.modal-button {
  background-color: transparent;
  height: 40px;
  margin: 0;
  padding: 0;
}

.content-button {
  border: 0;
  text-align: left;
  justify-content: left; // why no work
  font-weight: $default-bold;
  margin-left: 5px;
}

.modal-text-input {
  align-self: stretch;
  height: 40px;
  border-radius: 4px;
  border: 1px solid #d4d4d8;
  padding: 8px 12px;
}

.uploaded {
  margin-left: 10px;
  border: 1px solid #d4d4d8;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.06), 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0px 8px;
  height: 40px;
  vertical-align: middle;
  font-weight: $heavy-bold;
  font-size: $default-size;
  color: $green-darker;
  box-sizing: border-box;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
  box-sizing: border-box;
}
