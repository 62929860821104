@import '../../styles/variables';

.tag-item-row {
  display: flex;
  align-items: center;
}

.tags-page-column-titles {
  font-weight: 400;
  font-size: $default-size;
}
