@import '../../styles/variables';

input[type='submit'] {
  height: 40px;
  border: 1px solid $green-darker;
  border-radius: 4px;
  background-color: $green-darker;
  color: white;
}

.center {
  justify-content: center;
  align-items: center;
  text-align: center;
}

.main {
  margin-left: 40px;
  width: 90%;
  padding: 30px;
  // overflow: auto;
}

.delete-button {
  height: 40px;
  border: 1px solid $red2;
  border-radius: 4px;
  background-color: $red2;
  color: white;
}
