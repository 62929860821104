@import '../../styles/variables.scss';

.sideBar-container {
  min-height: 100vh;
  background-color: $gray-light;
  color: $gray400;
  overflow-x: hidden;
  z-index: 1;
  h1 {
    padding: 35px 20px;
    width: 100%;
    text-align: left;
    margin: 0;
    font-size: 28px;
  }

  .sideBar-items {
    .sideBar-item,
    .sideBar-item-active {
      display: flex;
      align-items: center;
      padding: 14px 20px;
      font-weight: $default-bold;

      &:hover {
        cursor: pointer;
      }
    }

    .sideBar-item-active {
      box-shadow: inset 4px 0px 0px $blue-border;
      background-color: $gray-medium;
      color: $gray600;
    }
  }
}

.sideBar-business-image {
  width: 24px;
  height: 24px;
}
