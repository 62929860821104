@import '../../styles/variables.scss';

.end-user-file-list-current-row {
  position: static;
  &:before {
    border-bottom: solid 1px $gray-border;
    content: '';
    background-color: var(--vid-customizations-primary-color);
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0px;
  }
}
