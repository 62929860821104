@import '../../styles/variables';

.login-container {
  width: 515px;
  height: 428px;
  padding: 48px;
  box-shadow: 0px 20px 25px rgba(0, 0, 0, 0.1),
    0px 10px 10px rgba(0, 0, 0, 0.04);
  border-radius: 4px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: white;

  input {
    width: 100%;
  }
  input[type='submit'] {
    background: $green-darker;
    border: none;
    font-weight: 600; // why isn't this working
    color: white;
  }
  a {
    color: $green-darker;
    font-weight: $default-bold;
  }
  .invalid-credentials {
    width: 100%;
    padding: 10px;
    background: #fee2e2;
    border: 1px solid #ef4444;
    color: #dc2626;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
  }
  .password-icon {
    border: none;
  }
}

.main-login {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
