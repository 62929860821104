@import '../../styles/variables';

.end_user_search_result_path {
  font-size: $small-font;
}

.end_user_search_result_description {
  font-size: $small-font;
}

.end_user_folder_search_result {
  // So, this is pretty hacky,
  // but what it does is hide a border
  // that I can't conditionally remove otherwise
  &::before {
    content: '';
    height: 5px;
    width: 100%;
    z-index: 2;
    background-color: var(--vid-customizations-secondary-color);
    position: absolute;
    left: 0px;
    bottom: 0px;
  }
}
