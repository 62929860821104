.system_table_row {
  .system_table_hover_button {
    visibility: hidden;
  }
  &:hover {
    .system_table_hover_button {
      visibility: visible;
    }
  }
}
