@import '../../styles/variables.scss';

button {
  height: 40px;
  border-radius: 4px;
  background-color: transparent;
  color: $green-darker;
  vertical-align: middle;
  margin-left: 5px;
  margin-right: 5px;
}
