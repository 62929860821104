@import './styles/variables.scss';

body {
  margin: 0;
  font-family: 'Helvetica Neue', -apple-system, BlinkMacSystemFont, 'Arial',
    Segoe UI, 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', sans-serif;
  font-size: $default-size;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  min-height: 100%;
  height: 100vh;
  // overflow: hidden;
}

.css-1yb2anq {
  overflow: unset !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.firebase-emulator-warning {
  display: none;
}

i,
.icon {
  padding-left: 5px;
  padding-right: 5px;
  min-width: 12px;
  min-height: 12px;
  font-size: $default-size;
}

i,
.faIcon {
  size: 22;
}

#logo {
  padding-left: 0px;
}

button {
  height: 40px;
  padding: 0 10px;
  border: 1px solid $green-darker;
  border-radius: 4px;
  background-color: transparent;
  color: $green-darker;
  vertical-align: middle;
  font-weight: $default-bold;
  font-size: $default-size;
  &:hover {
    cursor: pointer;
  }
}

.button-inverse {
  background-color: $green-darker;
  border: none;
  color: white;
  height: 40px;
  padding: 0 10px;
  vertical-align: middle;
  font-weight: $default-bold;
  font-size: $default-size;
  &:hover {
    cursor: pointer;
  }
}

.icon-button {
  border: 0;
}

input {
  height: 40px;
  border-radius: 4px;
  border: 1px solid $gray-border;
  font-size: $default-size;
  &:hover {
    cursor: pointer;
  }
}
// input[type='text'],
input[type='password'] {
  padding-inline-start: 12px;
  padding-inline-end: 12px;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $green-darker;
  }
  &:hover {
    cursor: text;
  }
}
input[type='checkbox']:checked {
  background: $green-darker; // TODO: why not work
}
.space-between {
  justify-content: space-between;
}

.width100 {
  width: 100%;
}

.left-border {
  box-shadow: inset 4px 0px 0px $blue-border;
}

label {
  font-weight: $heavy-bold;
  font-size: 14px;
  color: $gray600;
}
