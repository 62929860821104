@import '../../styles/variables';

// react-sortable-tree overrides

.rstcustom__rowSearchMatch {
  box-shadow: none;
}

.rstcustom__row,
.rstcustom__rowContents,
.rstcustom__rowLabel {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.rstcustom__rowWrapper > div {
  height: 100%;
}

.rstcustom__rowLandingPad {
  width: 100%;
}
.rstcustom__rowWrapper {
  border-bottom: 1px solid $gray-border;
  justify-content: center;
}

.rst__rowLandingPad::before,
.rst__rowCancelPad::before,
.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
  background-color: $green-original; //TODO: no work
}
.first {
  // later integrate this with .row
  width: 80%;
  display: flex;
  background-color: $gray-light;
  border-bottom: 1px solid $gray-border;
  padding: 10px;
}

.row {
  width: 80%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid $gray-border;
  padding: 10px;
  height: 40px;
}

.actionButton {
  border: 1px solid $green-darker;
  color: $green-darker;
  border-radius: 1000px;
  background-color: transparent;
  vertical-align: center;
  padding-left: 10px;
  padding-right: 10px;
}

#delete {
  border-color: $red;
  color: $red;
}

.content,
.folder {
  font-weight: 400;
  font-size: $default-size;
  &:hover {
    cursor: pointer;
    text-decoration: underline;
  }
}

.location-active {
  background-color: $blue50;
  box-shadow: inset 4px 0px 0px $blue-border;
}
